import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { CustomMaterialModule } from './custom-material/custom-material.module';
import { AppRoutingModule } from './app-routing.module';
import { LoggerModule } from 'ngx-logger';
import { environment } from '../environments/environment';
import { ListHistoryComponent } from './auth/update-patient/list-history/list-history.component';

//1. Add your credentials from step 1
 import { AngularFireModule } from '@angular/fire';
 import { AngularFireDatabaseModule } from '@angular/fire/database';
 import { AngularFirestoreModule } from '@angular/fire/firestore';

// 2. Add your credentials from step 1
const config = {
  apiKey: "AIzaSyDoAYwd2l1unw0FmcY4vNtUMP0-cgj84AQ",
    authDomain: "my-duc-hospital.firebaseapp.com",
    projectId: "my-duc-hospital",
    storageBucket: "my-duc-hospital.appspot.com",
    messagingSenderId: "844589935127",
    appId: "1:844589935127:web:a72f5b36129a39d1e74a52",
    measurementId: "G-E60SXMXV5K"
};

@NgModule({
  declarations: [
    AppComponent,
    ListHistoryComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    CustomMaterialModule.forRoot(),
    AppRoutingModule,
    LoggerModule.forRoot({
      serverLoggingUrl: `http://my-api/logs`,
      level: environment.logLevel,
      serverLogLevel: environment.serverLogLevel
    }),  
    
    AngularFireModule.initializeApp(config),
    AngularFireDatabaseModule,
    AngularFirestoreModule
  ],
  bootstrap: [AppComponent],
  entryComponents: [ListHistoryComponent]
})
export class AppModule { }
