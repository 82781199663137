export class RequestPatientShedule {
    id: string = "";
    scheduleId: string = "";
    patientId: string = "";
    clinicId: string = "";
    statusId: string = "";
    appointmentTypeId: number = 1;
    note: string = "";
}

export class InforUpdatePatientShedule {
    specializationMapping: any;
    date: string = "";
    time: string = "";
    name: string = "";
    dateOfBirth: string = "";
    timeText: string = "";
}

export class InfoClinicAddress {
    address: string = "";
    branch: number = 0;
    description: string = "";
    id: string = "";
    image: string = "";
    name: string = "";
    phone: string = "";
    checked : boolean = false;
}
