import { Component, OnInit, ChangeDetectorRef, OnDestroy, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { TimerObservable } from 'rxjs/observable/TimerObservable';
import { Subscription } from 'rxjs';

import { AuthenticationService } from './../../core/services/auth.service';
import { SpinnerService } from '../../core/services/spinner.service';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { SignalrService } from 'src/app/service/signalr.service';
import { NotificationSystemService } from 'src/app/core/services/notification-system.service';
import { formatDate } from '@angular/common';
import { NotificationSystem } from 'src/app/model/notification-sytem/notification-sytem';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { ViewReportService } from 'src/app/core/services/view_report.service';
import { ViewPlatFormType, ViewTypes } from 'src/app/model/view-used';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.css'],
    encapsulation: ViewEncapsulation.None,
})

export class LayoutComponent implements OnInit, OnDestroy, AfterViewInit {
    private _mobileQueryListener: () => void;
    mobileQuery: MediaQueryList;
    showSpinner: boolean;
    userName: string;
    isAdmin: boolean;
    isQuanLy: boolean;
    countNotificationSystem: number = 0;
    notificationSystems: NotificationSystem[] = [];
    private autoLogoutSubscription: Subscription;
    titleHeader = '';
    isNgocLan: any;
    user: any;
    windowScrolled = true;

    viewReportAppointmentSchedule = ViewTypes.AppointmentSchedule;
    viewReportNews = ViewTypes.News;
    viewReportASQ3 = ViewTypes.ASQ3;
    viewReportProcedure = ViewTypes.Procedure;
    viewReportBabyKick = ViewTypes.BabyKick;
    viewReportMeasureGlucose = ViewTypes.MeasureGlucose;
    viewReportHistory = ViewTypes.History;
    viewReportNewborn = ViewTypes.Newborn;

    constructor(private changeDetectorRef: ChangeDetectorRef,
        private media: MediaMatcher,
        private authService: AuthenticationService,
        private authGuard: AuthGuard,
        private signalrService: SignalrService,
        private router: Router,
        private notificationSystemService: NotificationSystemService,
        private viewReportService: ViewReportService,
        private notificationService: NotificationService
    ) {
        this.mobileQuery = this.media.matchMedia('(max-width: 1000px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        // tslint:disable-next-line: deprecation
        this.mobileQuery.addListener(this._mobileQueryListener);
        this.user = this.authService.getCurrentUser();
        if (this.user != undefined && this.user != null) {
            this.signalrService.logoutManyDevice$.subscribe((x: any) => {
                this.signalrService.logoutAppWebSite();
                this.authService.logout();
                this.notificationService.openSnackBar(`Tài khoản của quý khách đang đăng nhập trên ${x.DeviceName}`);
                this.router.navigate(['/auth/login']);
            });
        }
    }

    ngOnInit(): void {
        this.isNgocLan = localStorage.getItem('isHostNL');
        this.titleHeader = this.isNgocLan == 'true' ? 'PHÒNG KHÁM NGỌC LAN' : 'BỆNH VIỆN MỸ ĐỨC';
        this.getNotificationSystem();

        this.signalrService.newsReceived$.subscribe(() => {
            this.getNotificationSystem();
        });

        this.isAdmin = this.user.isAdmin;
        this.isQuanLy = this.user.isManager;
        this.userName = this.user.fullName;

        // Auto log-out subscription
        const timer = TimerObservable.create(2000, 5000);
        this.autoLogoutSubscription = timer.subscribe(() => {
            this.authGuard.canActivate();
        });
    }

    async getNotificationSystem() {
        let currentDate = new Date()
        var year = new Date(currentDate).getFullYear();
        var month = new Date(currentDate).getMonth();
        var day = new Date(currentDate).getDate();
        var date = new Date(year - 1, month, day);

        await this.notificationSystemService.getNotificationSystem(formatDate(date, 'dd/MM/yyyy', 'en-US'), formatDate(currentDate, 'dd/MM/yyyy', 'en-US'))
            .subscribe(response => {
                if (response !== null) {
                    this.notificationSystems = response;
                    this.countNotificationSystem = response.filter(x => x.isRead === false).length;
                }
            }, () => {

            });
    }

    notification(dataItem) {
        this.updateNotificationReader(dataItem);
        if (dataItem.typeId === 1) {
            window.location.href = 'view-news/' + dataItem.sourceId;
        }
        if (dataItem.typeId === 2) {
        }
    }

    onDeleteNotification(dataItem: any) {
        this.notificationSystemService.deteleNotificationSystem(dataItem.id).subscribe(() => { this.getNotificationSystem() })
    }

    updateNotificationReader(dataItem: any) {
        if (dataItem.isRead !== true) {
            let notificationIds = { notificationIds: [dataItem.id] }
            this.notificationSystemService.updateNotificationSystem(notificationIds).subscribe(() => {
                this.getNotificationSystem();
            })
        }
    }

    clickViewReport(id: string) {
        const postData = {
            viewTypeId: id,
            viewId: null,
            viewPlatformTypeId: ViewPlatFormType.Web
        }
        this.viewReportService.createdViews(postData).subscribe(() => { })
    }

    ngOnDestroy(): void {
        // tslint:disable-next-line: deprecation
        this.mobileQuery.removeListener(this._mobileQueryListener);
        this.autoLogoutSubscription.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.changeDetectorRef.detectChanges();

    }

    openNav() {
        document.getElementById("mySidenav").style.width = "70%";
        document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
    }

    closeNav() {
        document.getElementById("mySidenav").style.width = "0";
        document.body.style.backgroundColor = "rgba(0,0,0,0)";
    }

    logoutWeb() {
        this.signalrService.logoutAppWebSite();
    }
}
