import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

const appRoutes: Routes = [
    {
        path: 'auth',
        loadChildren: './auth/auth.module#AuthModule'
    },
    {
        path: 'home',
        loadChildren: './dashboard/dashboard.module#DashboardModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'pictures-baby',
        loadChildren: './pictures-baby/pictures-baby.module#PicturesBabyModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'users',
        loadChildren: './users/users.module#UsersModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'account',
        loadChildren: './account/account.module#AccountModule',
        canActivate: [AuthGuard]
    },
    // {
    //     path: 'fanpage',
    //     loadChildren: './icons/icons.module#IconsModule',
    //     canActivate: [AuthGuard]
    // },
    {
        path: 'about',
        loadChildren: './about/about.module#AboutModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'dat-lich-hen',
        loadChildren: './make-appointment/make-appointment.module#MakeAppointmentModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'management-appointment-schedule',
        loadChildren: './admin/management-appointment-schedule/management-appointment-schedule.module#ManagementAppointmentScheduleModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'update-patient',
        loadChildren: './auth/update-patient/update-patient.module#UpdatePatientModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'examination-process',
        loadChildren: './examination-process/examination-process.module#ExaminationProcessModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'decentralization',
        loadChildren: './admin/decentralization/decentralization.module#DecentralizationModule',
        canActivate: [AuthGuard]
    },
    // {
    //     path: 'treatmen_tregimen',
    //     loadChildren: './admin/treatment-tregimen/treatmen-tregimen.module#TreatmenTregimenModule',
    //     canActivate: [AuthGuard]
    // },
    {
        path: 'electronic-bill',
        loadChildren: './electronic-bill/electronic-bill.module#ElectronicBillModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'ultrasound-results',
        loadChildren: './ultrasound-results/ultrasound-results.module#UltrasoundResultsModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'measure-glucosen',
        loadChildren: './measure-glucosen/measure-glucosen.module#MeasureGlucosenResultsModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'news',
        loadChildren: './admin/management-news/management-news.module#ManagementNewsModule',
        canActivate: [AuthGuard]
    },

    {
        path: 'view-news',
        loadChildren: './view-news/view-news.module#ViewNewsModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'video-library',
        loadChildren: './video-library/video-library.module#VideoLibraryModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'chat',
        loadChildren: './chat/chat.module#ChatModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'notification-user',
        loadChildren: './notification-user/notification-user.module#NotificationUserModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'asq-3',
        loadChildren: './list-child-asq3/list-child-asq3.module#ExaminationProcessModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'view-policy',
        loadChildren: './view-policy/view-policy.module#ViewPolicyModule',
        canActivate: [AuthGuard]
    },
    {
        path: '**',
        redirectTo: 'home',
        pathMatch: 'full'
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes)
    ],
    exports: [RouterModule],
    providers: []
})
export class AppRoutingModule { }
