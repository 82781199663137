import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class ViewReportService {
  constructor(private apiService: ApiService) { }

  getTypeReports() {
    return this.apiService.get(`api/v1/views/view-types`).pipe(map(res => {
      return res;
    }));
  }

  createdViews(request) {
    return this.apiService.post(`api/v1/views`, request).pipe(map(res => {
      return res;
    }));
  }

  createdViewsFeedback(request) {
    return this.apiService.post(`api/v1/views/feedback`, request).pipe(map(res => {
      return res;
    }));
  }
}
