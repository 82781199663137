import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CustomMaterialModule } from '../custom-material/custom-material.module';
import { LimitToPipe } from './pipes/limit-to.pipe';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ContentPlaceholderAnimationComponent } from './content-placeholder-animation/content-placeholder-animation.component';
import { LocalDatePipe } from './pipes/local-date.pipe';
import { YesNoPipe } from './pipes/yes-no.pipe';
import { LayoutComponent } from './layout/layout.component';
import { DateSelectComponent } from './date-select/date-select.component';
import { FooterComponent } from './footer/footer.component';
import { ByPassSecurityPipe } from './pipes/Sanitize.pipe';
import { ScrollToTopComponent } from './scroll-to-top/scroll-to-top.component';
import { SafePipe } from './pipes/safe.pipe';

@NgModule({
  imports: [
    RouterModule,
    CustomMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
  ],
  declarations: [
    ConfirmDialogComponent,
    ScrollToTopComponent,
    ContentPlaceholderAnimationComponent,
    LimitToPipe,
    LocalDatePipe,
    YesNoPipe,
    LayoutComponent,
    DateSelectComponent,
    FooterComponent,
    ByPassSecurityPipe,
    SafePipe
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    CustomMaterialModule,
    LimitToPipe,
    ConfirmDialogComponent,
    ScrollToTopComponent,
    ContentPlaceholderAnimationComponent,
    LocalDatePipe,
    ByPassSecurityPipe ,
    DateSelectComponent,
    YesNoPipe,
    SafePipe
  ],
  entryComponents: [
    ConfirmDialogComponent,   
    DateSelectComponent
  ]
})
export class SharedModule { }
