import { Injectable } from "@angular/core";
import { HubConnection, HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { MessagePackHubProtocol } from "@microsoft/signalr-protocol-msgpack";
import { from, Subject } from "rxjs";
import { AuthenticationService } from "../core/services/auth.service";
import { ApiService } from "./api.service";

@Injectable({ providedIn: "root" })
export class SignalrService {
  public hubConnection: HubConnection;
  public connectionUrl: string;
  public newsReceived$ = new Subject<any[]>();
  public logoutManyDevice$ = new Subject<any[]>();
  public user: any;

  constructor(private authService: AuthenticationService, private apiService: ApiService) {
    this.connectionUrl = apiService.getBaseUrl() + "notifications";
  }

  detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf("edge") > -1:
        return "edge";
      case agent.indexOf("opr") > -1 && !!(<any>window).opr:
        return "opera";
      case agent.indexOf("chrome") > -1 && !!(<any>window).chrome:
        return "chrome";
      case agent.indexOf("trident") > -1:
        return "ie";
      case agent.indexOf("firefox") > -1:
        return "firefox";
      case agent.indexOf("safari") > -1:
        return "safari";
      default:
        return "other";
    }
  }

  public connect = () => {
    this.user = this.authService.getCurrentUser();
    this.startConnection(this.user);
    this.addListeners();
    this.NotificationLogoutServer();
    this.LoginConnectionId();
  };

  public disconnectLogin = () => {
    this.hubConnection.stop().then(() =>
      console.log("Connection stop!")).catch((err) =>
        console.log("Error while establishing a connection :( ")
      );
  };

  public sendMessageToHub(message: string) {
    var promise = this.hubConnection.invoke("BroadcastAsync", this.buildChatMessage(message)).then(() => {
      console.log(message);
    }).catch((err) =>
      console.log("error while sending a message to hub: " + err)
    );
    return from(promise);
  }

  public logoutAppWebSite() {
    this.disconnectLogin();
  }

  public joinGroup(group) {
    var promise = this.hubConnection.invoke("subscribeGroup", group)
      .then(() => { console.log("group:.....", group); })
      .catch((err) =>
        console.log("error while sending a message to hub: " + err)
      );
    return from(promise);
  }

  private getConnection(user): HubConnection {
    const deviceId = this.detectBrowserName() + Math.random();
    localStorage.setItem('deviceName', deviceId);
    let url = this.connectionUrl + `?userId=${user.userId}&deviceId=${deviceId}&deviceName=${this.detectBrowserName()}&refreshToken=${user.refreshToken}`;
    return new HubConnectionBuilder()
      .withUrl(url, { accessTokenFactory: () => user.token })
      .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000])
      .withHubProtocol(new MessagePackHubProtocol())
      .build();
  }

  private buildChatMessage(message: string): chatMessage {
    return {
      ConnectionId: this.hubConnection.connectionId,
      Text: message,
      DateTime: new Date(),
    };
  }

  private startConnection(user) {
    this.hubConnection = this.getConnection(user);

    if (this.hubConnection.state === HubConnectionState.Connected) {
      return;
    }
    this.hubConnection.start().then().catch((err) => console.log("Error while establishing a connection :()"));
  }

  private LoginConnectionId() {
    this.hubConnection.on("LoginData", (data) => {
      localStorage.setItem("ConnectionId", data);
    });
  }

  private addListeners() {
    this.hubConnection.on("NotificationFromServer", (type, data) => {
      let nextData: [{ type; data }];
      this.newsReceived$.next(nextData);
    });
  }

  private NotificationLogoutServer() {
    this.hubConnection.on("NotificationLogoutServer", (type, data) => {    
      this.logoutManyDevice$.next(data);
    });
  }

  public statusConnect() {
    return this.hubConnection.state;
  }
}

export interface chatMessage {
  Text: string;
  ConnectionId: string;
  DateTime: Date;
}
