import { Injectable, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/delay';
import { of } from 'rxjs';
import { ApiService } from 'src/app/service/api.service';

const routes = 'api/'
@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    constructor(
        private apiService: ApiService,
        @Inject('LOCALSTORAGE') private localStorage: Storage) {
    }

    login(request) {
        return this.apiService.post(routes + 'tokens', request).pipe(map(res => {
            this.localStorage.setItem('currentUser', JSON.stringify({
                token: res.token,
                refreshToken: res.refreshToken,
                expiration: res.refreshTokenExpiryTime,
                username: res.username,
                isRegisterPhone: res.isRegisterPhone,
                userId: res.userId,
                isAdmin: res.isAdmin,
                isManager: res.isManager,
                patientDefaultId: res.patientDefaultId,              
            }));
            return res;
        }));
    }

    register(request) {
        return this.apiService.post(routes + 'users/self-register', request)
            .pipe(map(res => {
                return res;
            }));
    }

    logout(): void {
        // clear token remove user from local storage to log user out        
        this.localStorage.removeItem('currentUser');
        localStorage.removeItem('currentRoles');
        localStorage.removeItem('createdPassword');
        localStorage.removeItem('createdPassword');
    }

    getCurrentUser(): any {
        return JSON.parse(this.localStorage.getItem('currentUser'));
    }

    passwordResetRequest() {
        return of(true).delay(1000);
    }

    changePassword(request) {
        return this.apiService.put(routes + 'personal/change-password', request)
            .pipe(map(res => {
                return res;
            }));
    }

    passwordReset(email: string, token: string, password: string, confirmPassword: string): any {
        return of(true).delay(1000);
    }

    sendOTP(request) {
        return this.apiService.post(routes + 'v1/otp/otp', request)
            .pipe(map(res => {
                return res;
            }));
    }

    sendLoginOTP(request) {
        return this.apiService.post(routes + 'v1/otp/login', request)
            .pipe(map(res => {
                return res;
            }));
    }

    sendforgetPass(request) {
        return this.apiService.post(routes + 'users/forgot-password', request)
            .pipe(map(res => {
                return res;
            }));
    }

    resetPass(request) {
        return this.apiService.post(routes + 'users/reset-password', request)
            .pipe(map(res => {
                return res;
            }));
    }

    confirmOTP(request) {
        return this.apiService.post(routes + 'v1/otp/otp_confirm', request)
            .pipe(map(res => {
                return res;
            }));
    }

    getInforUser() {
        return this.apiService.get(routes + 'personal/profile').pipe(map(res => {
            return res;
        }));
    }

    getListHistory(id) {
        return this.apiService.get(routes + 'v1/appointmentschedules/' + id)
            .pipe(map(res => {
                return res;
            }));
    }

    deletePatient(id) {
        return this.apiService.delete(routes + 'v1/patients/' + id)
            .pipe(map(res => {
                return res;
            }));
    }

    getConfirmAppointment(mabn) {
        return this.apiService.get(routes + 'v1/patients/appointment/' + mabn)
            .pipe(map(res => {
                return res;
            }));
    }

    cancelAppointment(request) {
        return this.apiService.post(routes + 'v1/patients/appointment', request)
            .pipe(map(res => {
                return res;
            }));
    }
}
