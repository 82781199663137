import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/delay';
import { of, EMPTY } from 'rxjs';
import { ApiService } from 'src/app/service/api.service';

const routes = 'api/v1/patients/'
@Injectable({
    providedIn: 'root'
})
export class MakeAppoinmentService {

    constructor(private http: HttpClient, private apiService: ApiService,
        @Inject('LOCALSTORAGE') private localStorage: Storage) {
    }



    getClinics() {
        return this.apiService.get('api/v1/clinics')
            .pipe(map(res => {
                return res;
            }));
    }

    getAllSpecializations(idBranch) {
        return this.apiService.get('api/v1/specializations/' + idBranch)
            .pipe(map(res => {
                return res;
            }));
    }

    getDoctor(specialization_id) {
        return this.apiService.get('api/v1/doctors/' + specialization_id + '/specializations')
            .pipe(map(res => {
                return res;
            }));
    }

    searchByDate(clinic_id, fromDate, toDate, specializationMapping) {
        return this.apiService.get('api/v1/schedules/' + clinic_id + '/clinics/' + 'range?fromDate=' + fromDate + '&toDate=' + toDate + '&specializationMapping=' + specializationMapping)
            .pipe(map(res => {
                return res;
            }));
    }

    getTypeTime() {
        return this.apiService.get('api/v1/types/timetypes')
            .pipe(map(res => {
                return res;
            }));
    }

    getTypeTimeByDate(clinic_id, patients, date, specializationMapping, isRegister) {
        return this.apiService.get('api/v1/schedules/' + clinic_id + '/clinics/' + patients +
            '/patients?date=' + date + '&specializationMapping=' + specializationMapping + '&isRegister=' + isRegister)
            .pipe(map(res => {
                return res;
            }));
    }

    getInfoConfirm(clinic_id, timetype, date, specialization, doctorId) {
        const doctor = doctorId === null ? '' : doctorId;
        return this.apiService.get('api/v1/schedules/' + clinic_id + '/clinic/' + timetype + '/timetype/' + specialization + '/specialization?date=' + date + '&doctorId=' + doctor)
            .pipe(map(res => {
                return res;
            }));
    }

    getInforPatient(patient) {
        return this.apiService.get('api/v1/patients/' + patient)
            .pipe(map(res => {
                return res;
            }));
    }

    addSchedules(request) {
        return this.apiService.post('api/v1/appointmentschedules', request)
            .pipe(map(res => {
                return res;
            }));
    }

    updateSchedules(id, request) {
        return this.apiService.put('api/v1/appointmentschedules/' + id, request)
            .pipe(map(res => {
                return res;
            }));
    }

    deleteSchedules(id) {
        return this.apiService.delete('api/v1/appointmentschedules/' + id)
            .pipe(map(res => {
                return res;
            }));
    }

    addBabyweek(request) {
        return this.apiService.post('api/v1/patients/babyweek', request)
            .pipe(map(res => {
                return res;
            }));
    }

    getPregnancyfee(branch, pregnancyWeek, days) {
        return this.apiService.get('api/v1/treatmentregimen/pregnancy-fee/' + branch + '/clinics?pregnancyWeek=' + pregnancyWeek + '&days=' + days)
            .pipe(map(res => {
                return res;
            }));
    }

    getAppointmentschedulesByDate(clinic_id, fromDate, toDate) {
        return this.apiService.get('api/v1/appointmentschedules/' + clinic_id + '/clinics?' + 'fromDate=' + fromDate + '&toDate=' + toDate)
            .pipe(map(res => {
                return res;
            }));
    }

    getStatus() {
        return this.apiService.get('api/v1/types/statuses')
            .pipe(map(res => {
                return res;
            }));
    }

    updateAppointmentSchedules(request, id) {
        return this.apiService.put('api/v1/appointmentschedules/' + id, request)
            .pipe(map(res => {
                return res;
            }));
    }

    getsShedulesDoctor(clinicsId, patientId, date, specializationMappingId) {
        return this.apiService.get('api/v1/schedules/' + clinicsId + '/clinics/' + patientId + '/patients?date=' + date + '&specializationMapping=' + specializationMappingId + '&isRegister=false')
            .pipe(map(res => {
                return res;
            }));
    }
}
