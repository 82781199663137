import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor() { }

  titleFooter = '';
  href = '';
  ngOnInit() {
    if (localStorage.getItem('isHostNL') == 'true') {
      this.titleFooter = 'Phòng khám Ngọc Lan';
      this.href = 'https://phongkhamngoclan.vn/';
    }
    else {
      this.titleFooter = 'Bệnh Viện Đa Khoa Mỹ Đức'
      this.href = 'https://myduchospital.vn/';
    }
  }

}
