import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(private snackBar: MatSnackBar) { }

    public openSnackBar(message: string) {
        this.snackBar.open(message, '', {
            duration: 3000,
            panelClass: ['warning']
        });
    }

    public getLogo() {
        let src = ''
        if (localStorage.getItem('isHostNL') == 'true') {
            src = 'assets/images/logo_nl.png';
        }
        else {
            src = 'assets/images/logo.svg';
        }
        return src;
    }
}
