import { formatDate, getLocaleDateTimeFormat } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { MatCalendarCellCssClasses, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { MakeAppoinmentService } from 'src/app/core/services/make.appoinment.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { SelectDoctorComponent } from 'src/app/make-appointment/select-doctor/select-doctor.component';
import { Clinic } from 'src/app/model/patient/clinic';

@Component({
  templateUrl: './patient-schedule.component.html',
  styleUrls: ['./patient-schedule.component.css']
})
export class PatientScheduleComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private makeAppoinmentService: MakeAppoinmentService,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<PatientScheduleComponent>,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService
  ) {
    this.nowDate = new Date(this.nowDate.getFullYear(), this.nowDate.getMonth(), this.nowDate.getDate() + 1);
    this.maxDate = new Date(this.nowDate.getFullYear(), this.nowDate.getMonth(), this.nowDate.getDate() + 14);
  }

  nowDate = new Date();
  maxDate: Date;

  public isMobile = this.data.isMobile;
  public showConcactHospital = false;

  enumClinic = Clinic;

  request = this.data.requestPatientShedule;
  inforUpdate = this.data.inforUpdatePatientShedule;

  dateKham = new Date();
  ngayDatKham = new Date();
  chonNgay: any;
  showWarning: string = "Quý khách vui lòng liên hệ bệnh viện qua số điện thoại 028.7308 5885/ 028 6285 6262 để được hỗ trợ";

  ngOnInit() {
    setTimeout(() => {
      this.getSpecializations();
      let date = this.inforUpdate.date.slice(0, 2);
      let month = this.inforUpdate.date.slice(3, 5);
      let year = this.inforUpdate.date.slice(6, 10);
      this.dateKham = new Date(month + '/' + date + '/' + year);
      this.getTypeTimeByDate(this.dateKham);

      //==================== SET CURRENT DATATIME =================
      this.ngayDatKham = this.dateKham;
      this.chonNgay = this.dateKham;
    });
  }

  specializations = []
  getSpecializations() {
    this.makeAppoinmentService.getAllSpecializations(this.request.clinicId).subscribe(
      rs => {
        if (rs == null) {
          this.specializations = [];
          return 0;
        }
        this.specializations = rs.data;
        this.searchByDate();
      });

  }

  statusDate = [{ date: new Date(), isDisable: false , message : null },];

  searchByDate() {
    this.makeAppoinmentService.searchByDate(this.request.clinicId, formatDate(this.nowDate, 'dd/MM/yyyy', 'en-US'), formatDate(this.maxDate, 'dd/MM/yyyy', 'en-US'), this.inforUpdate.specializationMapping).subscribe(
      rs => {
        if (rs == null || rs.data.length == 0) {
          this.statusDate = [];
          return 0;
        }
        this.statusDate = rs.data;
        this.rangeFilter = (date: Date): boolean => {
          let status = false;
          for (let i = 0; i < this.statusDate.length; i++) {
            if (formatDate(date, 'dd/MM/yyyy', 'en-US') == formatDate(this.statusDate[i].date, 'dd/MM/yyyy', 'en-US')) {
              status = !this.statusDate[i].isDisable;
              break;
            }
          }
          return status;
        }
      });
  }
  rangeFilter: any

  dateClass() {
    return (date: Date): MatCalendarCellCssClasses => {
      let status = false;
      let isMessage = false;
      for (let i = 0; i < this.statusDate.length; i++) {
        if (formatDate(date, 'dd/MM/yyyy', 'en-US') == formatDate(this.statusDate[i].date, 'dd/MM/yyyy', 'en-US')) {
          status = !this.statusDate[i].isDisable;
          isMessage = this.statusDate[i].message;
          if (this.statusDate[i].message !== null) {
            return 'holiday';
          } else {
            if (status)
              return 'special-date';
            else {
              return;
            }
          }
        }
      }
    };
  }

  getDateChangedValue(event) {
    this.chonNgay = event.toDate();
    if (this.ngayDatKham > this.nowDate && (this.chonNgay.getUTCDate() - this.ngayDatKham.getUTCDate() > 2 || this.ngayDatKham.getUTCDate() - this.chonNgay.getUTCDate() > 2)) {
      if (!this.isMobile) {
        this.notificationService.openSnackBar(this.showWarning);
      }
      else {
        this.showConcactHospital = true;
      }
    }
    else {
      this.showConcactHospital = false;
      this.getTypeTimeByDate(event);
    }
  }

  typeTime = {};
  keysTime = [];

  getTypeTimeByDate(date) {
    this.makeAppoinmentService.getTypeTimeByDate(this.request.clinicId, this.request.patientId, formatDate(date, 'dd/MM/yyyy', 'en-US'), this.inforUpdate.specializationMapping, false).subscribe(
      rs => {
        if (rs == null || rs.data.length == 0) {
          this.typeTime = [];
          return 0;
        }
        this.typeTime = this.groupBy(rs.data, (c) => c.group);
        this.keysTime = Object.keys(this.typeTime)
      },
      err => {
        this.dateKham = null;
        this.notificationService.openSnackBar(err.messages.toString());
      });
  }

  groupBy(xs, f) {
    return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
  }

  changeSpecialization() {
    this.searchByDate();
  }

  onUpdatedSchedule() {
    this.spinner.show();

    if (this.ngayDatKham > this.nowDate && (this.chonNgay.getUTCDate() - this.ngayDatKham.getUTCDate() > 2 || this.ngayDatKham.getUTCDate() - this.chonNgay.getUTCDate() > 2)) {
      if (!this.isMobile) {
        this.notificationService.openSnackBar(this.showWarning);
      }
      else {
        this.showConcactHospital = true;
      }

      this.isTurnOfSpinner();
      return true;
    }
    const doctorId = this.request.doctor === undefined || this.request.doctor === null ? null : this.request.doctor;
    let tamChuyenKhoa = this.specializations.filter(item => item.specializationMapping == this.inforUpdate.specializationMapping);
    this.makeAppoinmentService.getInfoConfirm(this.request.clinicId, this.inforUpdate.time, formatDate(this.dateKham, 'dd/MM/yyyy', 'en-US'), tamChuyenKhoa[0].id, doctorId).subscribe(
      rs => {
        if (rs) {
          if (this.isBuoiSang && this.request.doctor != null) {
            this.request.scheduleId = this.request.scheduleId;
          }
          else {
            this.request.scheduleId = rs.sheduleId;
          }

          this.makeAppoinmentService.updateSchedules(this.request.id, this.request).subscribe(
            rs => {
              this.dialogRef.close(true);
              this.isTurnOfSpinner();
            },
            errors => {
              this.isTurnOfSpinner();
              this.notificationService.openSnackBar(errors.messages.toString());
            });
        }
      }, error => {
        this.isTurnOfSpinner();
        this.notificationService.openSnackBar(error.messages.toString());
      });
  }

  isTurnOfSpinner() {
    setTimeout(() => {
      this.spinner.hide();
    }, 100);
  }

  isBuoiSang: boolean = false;
  selectHour(option: any) {
    this.inforUpdate.time = option.id;
    this.isBuoiSang = false;

    this.request.sheduleId = null;
    if (option.group === 1) {
      this.isBuoiSang = true;
      this.getShedulesDoctor(
        this.request.clinicId,
        this.request.patientId,
        formatDate(this.dateKham, 'dd/MM/yyyy', 'en-US'),
        this.request.specializationMapping,
        option
      );
    }
  }

  doctorsFllowShedule = [];
  getShedulesDoctor(branch: any, patientId: any, dateKham: any, specializationMapping: any, dataItem: any) {
    this.doctorsFllowShedule = [];
    this.makeAppoinmentService.getsShedulesDoctor(branch, patientId, dateKham, specializationMapping).subscribe(
      rs => {
        if (rs == null || rs.data.length == 0) {
          this.doctorsFllowShedule = [];
          return 0;
        }
        const dtDoctors = rs.data.filter(c => c.time === dataItem.time).map(c => c.doctors);
        if (dtDoctors != undefined) {
          for (let index = 0; index < dtDoctors.length; index++) {
            const element: any[] = dtDoctors[index];
            for (let i = 0; i < element.length; i++) {
              const item = element[i];
              this.doctorsFllowShedule.push(item);
            }
          }
        }
        if (this.doctorsFllowShedule.length > 0) {
          this.dialog.open(SelectDoctorComponent, {
            disableClose: true,
            width: '400px',
            data: {
              doctors: this.doctorsFllowShedule
            }
          }).afterClosed().subscribe(result => {
            if (result != undefined && result != "" && result.length > 0) {
              this.request.sheduleId = result[0].scheduleId;
              this.request.scheduleId = result[0].scheduleId;
              this.request.doctor = result[0].doctorId;
            }
          });
        }
      },
      err => {
        this.notificationService.openSnackBar(err.messages.toString());
      });
  }

  // showMessageSelectHour(): string {
  //   let warningHour: string = "Vui lòng chọn ngày khám hôm sau";
  //   if (this.nowDate.getDate() === this.chonNgay.getDate()) {
  //     let getHourOfDay = this.nowDate.getHours();
  //     if (getHourOfDay > 10) {
  //       this.notificationService.openSnackBar(warningHour);
  //       return warningHour;
  //     }
  //   }
  // }
}
