import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { MakeAppoinmentService } from 'src/app/core/services/make.appoinment.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { MatDialog } from '@angular/material';
import { PatientScheduleComponent } from '../patient-schedule/update-schedule.component';
import { InforUpdatePatientShedule, RequestPatientShedule } from 'src/app/model/patient/patient';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { Clinic } from 'src/app/model/patient/clinic';
import { ConfirmCancelDialogComponent } from '../confirm-cancel-dialog/confirm-cancel-dialog.component';
import { EditStatusScheduleComponent } from '../edit-status-schedule/edit-status-schedule.component';

@Component({
  templateUrl: './list-history.component.html',
  styleUrls: ['./list-history.component.css']
})

export class ListHistoryComponent implements OnInit {
  public id = '';
  public name = '';
  public listHis = [];
  public isMobile: boolean;

  public requestPatientShedule = new RequestPatientShedule();
  public inforUpdatePatientShedule = new InforUpdatePatientShedule();
  public enumClinic = Clinic;

  constructor(
    private makeAppoinmentService: MakeAppoinmentService,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    if (window.screen.width < 1024) {
      this.isMobile = true;
    }
    else {
      this.isMobile = false;
    }
  }

  cancelCalendar(dataItem) {
    if (dataItem.manageCode === "") {
      this.dialog.open(EditStatusScheduleComponent,
        {
          disableClose: false,
          width: '400px',
          data: { title: 'Xác nhận', message: "Bạn có chắc chắn muốn hủy lịch hẹn này ?" }
        }).afterClosed().subscribe(result => {
          if (result.isTrue) {
            let tamRequest = {
              id: dataItem.id,
              scheduleId: dataItem.scheduleId,
              patientId: dataItem.patientId,
              clinicId: dataItem.clinicId,
              statusId: 'f55ff352-d501-4045-ae1b-18b4ea05210f', // enum Hủy 
              appointmentTypeId: dataItem.appointmentTypeId,
              note: result.note,
            }
            this.makeAppoinmentService.updateAppointmentSchedules(tamRequest, dataItem.id).subscribe(() => {
              this.getInfoHistory();
              this.notificationService.openSnackBar(`Đã hủy lịch hẹn thành công`);
            }, () => {
              this.notificationService.openSnackBar(`Hủy lịch hẹn không thành công`);
            });
          }
        });
    }
    else {
      const phone = dataItem.clinicId === this.enumClinic.ClinicTanBinh ? `02873085885` : `02862856262`;
      this.dialog.open(ConfirmCancelDialogComponent,
        {
          disableClose: false,
          width: '400px',
          data: {
            title: 'Lịch khám không hủy được',
            message: `Quý khách vui lòng liên hệ bệnh viện qua số điện thoại ${phone.bold().toLowerCase()}, lý do bác sĩ đã đặt lịch.`
          }
        }).afterClosed().subscribe(result => {
          if (result) { }
        });
    }
  }

  getInfoHistory() {
    this.authenticationService.getListHistory(this.id).subscribe(
      rs => {
        this.listHis = rs.data.filter(c => c.statusName !== "Đã hủy");;
      });
  }

  showPopupScheludeForPerson(item: any) {
    this.requestPatientShedule = item;

    //item.specializationMapping;
    this.inforUpdatePatientShedule.name = this.name;
    this.inforUpdatePatientShedule.specializationMapping = item.specializationMapping;
    this.inforUpdatePatientShedule.date = item.date;
    this.inforUpdatePatientShedule.time = item.timeFrameId;
    this.inforUpdatePatientShedule.dateOfBirth = item.dateOfBirth;
    this.inforUpdatePatientShedule.timeText = item.time;

    this.dialog.open(PatientScheduleComponent, {
      height: '95%',
      width: '1300px',
      maxWidth: '99vw',
      data: {
        isMobile: this.isMobile,
        requestPatientShedule: this.requestPatientShedule,
        inforUpdatePatientShedule: this.inforUpdatePatientShedule
      }
    }).afterClosed().subscribe((result) => {
      if (result != undefined && result != null && result != "") {
        this.getInfoHistory();
      }
    });
  }
}
