import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, share } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
// import { WarningComponent } from 'src/app/shared/components/warning/warning.component';
// import { Message } from '/message';

// const BASE_URL = 'http://172.16.1.15:8085/';
// const BASE_URL2 = 'http://172.16.1.15:8085/';
// const BASE_URL_INTER = 'http://172.16.1.15:8085/';

const BASE_URL = 'https://patient.myduchospital.vn:8084/';
const BASE_URL2 = 'https://patient.myduchospital.vn:4433/tinasoft/hyvong/';
const BASE_URL_INTER = 'https://patient.myduchospital.vn:4433/tinasoft/hyvong/';

// const BASE_URL = 'http://172.16.1.35:9999/tinasoft/hyvong/';
// const BASE_URL2 = 'http://172.16.1.35:9999/tinasoft/';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Accept-Language': 'vi',
      // 'Access-Control-Allow-Origin': '*'
      // Tenant: localStorage.getItem('tenantId') == null ? '' : localStorage.getItem('tenantId')
      'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
    })
  };

  constructor(
    private httpClient: HttpClient,
    public dialog: MatDialog,
    // private message: Message,
  ) {
  }

  public get(path: string, params: HttpParams = new HttpParams()
  ): Observable<any> {
    this.options.headers = new HttpHeaders({
      'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
      // Authorization: `Bearer ${access_token}`,
      'Content-Type': 'application/json; charset=utf-8',
      'Accept-Language': 'vi',
      // 'Access-Control-Allow-Origin': '*'
      // Tenant: localStorage.getItem('tenantId') == null ? '' : localStorage.getItem('tenantId')
    });
    return this.httpClient
      .get(BASE_URL + path, { ...this.options, params })
      .pipe(catchError(this.formatErrors));
  }

  public getHD(path: string, params: HttpParams = new HttpParams()
  ): Observable<any> {
    this.options.headers = new HttpHeaders({
      'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
      'Content-Type': 'application/json; charset=utf-8',
      'Accept-Language': 'vi',
    });
    return this.httpClient
      .get(BASE_URL_INTER + path, { ...this.options, params })
      .pipe(catchError(this.formatErrors));
  }

  public getFile(path: string, params: HttpParams = new HttpParams()
  ): Observable<any> {
    this.options.headers = new HttpHeaders({
      'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
      'Content-Type': 'application/json',
      'Accept-Language': 'vi',
      // Tenant: localStorage.getItem('tenantId') == null ? '' : localStorage.getItem('tenantId')
    });
    return this.httpClient
      .get(BASE_URL + path, { ...this.options, params, responseType: 'blob' })
      .pipe(
        catchError(this.formatErrors)
      );
  }

  public getFileOctetStream(path: string, params: HttpParams = new HttpParams()
  ): Observable<any> {
    this.options.headers = new HttpHeaders({
      'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
      'Content-Type': 'application/octet-stream',
      'Accept-Language': 'vi',
      // Tenant: localStorage.getItem('tenantId') == null ? '' : localStorage.getItem('tenantId')
    });
    return this.httpClient
      .get(BASE_URL + path, { ...this.options, params, responseType: 'blob' })
      .pipe(
        catchError(this.formatErrors)
      );
  }
  public put(path: string, body: object = {}): Observable<any> {
    this.options.headers = new HttpHeaders({
      'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
      'Content-Type': 'application/json',
      'Accept-Language': 'vi',
      // Tenant: localStorage.getItem('tenantId') == null ? '' : localStorage.getItem('tenantId')
    });
    return this.httpClient
      .put(BASE_URL + path, JSON.stringify(body), this.options)
      .pipe(catchError(this.formatErrors));
  }

  /**
   * Make put request and check version
   * @param path: any
   * @param body: any
   * @param callBack: any
   * @param noCallBack: any
   */
  public putAndCheckVersion(path: string, body: object = {}, callBack: any, noCallBack: any) {
    this.options.headers = new HttpHeaders({
      'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
      'Content-Type': 'application/json',
      'Accept-Language': 'vi',
      Tenant: localStorage.getItem('tenantId') == null ? '' : localStorage.getItem('tenantId')
    });
    this.httpClient
      .put(BASE_URL + path, JSON.stringify(body), this.options)
      .subscribe(rs => {
        const response: any = rs;
        if (response && response.code === '0x00122') {
          // const dialogRef = this.dialog.open(WarningComponent, {
          //   width: '350px',
          //   data: this.message.getMessage(response.message)
          // });
          // dialogRef.afterClosed().subscribe(rss => {
          //   if (rss) {
          //     const newBody: any = body;
          //     newBody.skipVersion = true;
          //     this.httpClient
          //       .put(BASE_URL + path, JSON.stringify(newBody), this.options)
          //       .pipe()
          //       .subscribe(rsss => {
          //         console.log(rsss);
          //         callBack();
          //       });
          //   } else {
          //     noCallBack();
          //   }
          // });
        } else {
          callBack();
        }
      });
  }

  public post(path: string, body: object = {}): Observable<any> {
    this.options.headers = new HttpHeaders({
      'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
      'Content-Type': 'application/json',
      'Accept-Language': 'vi',
      'tenant': 'root'
    });
    return this.httpClient
      .post(BASE_URL + path, JSON.stringify(body), this.options)
      .pipe(catchError(this.formatErrors));
  }

  public postHD(path: string, body: object = {}): Observable<any> {
    this.options.headers = new HttpHeaders({
      'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
      'Content-Type': 'application/json',
      'Accept-Language': 'vi',
      'tenant': 'root'
    });
    return this.httpClient
      .post(BASE_URL_INTER + path, JSON.stringify(body), this.options)
      .pipe(catchError(this.formatErrors));
  }

  public putHD(path: string, body: object = {}): Observable<any> {
    this.options.headers = new HttpHeaders({
      'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
      'Content-Type': 'application/json',
      'Accept-Language': 'vi',
      'tenant': 'root'
    });
    return this.httpClient
      .put(BASE_URL_INTER + path, JSON.stringify(body), this.options)
      .pipe(catchError(this.formatErrors));
  }

  public postView(path: string, body: object = {}): Observable<any> {
    this.options.headers = new HttpHeaders({
      'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
      'Content-Type': 'application/json',
      'Accept-Language': 'vi',
      'tenant': 'root'
    });
    return this.httpClient
      .post(BASE_URL2 + path, JSON.stringify(body), this.options)
      .pipe(catchError(this.formatErrors));
  }

  public postUpload(path: string, body: object = {}): Observable<any> {
    this.options.headers = new HttpHeaders({
      // 'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
      // 'Accept-Language': 'vi',
      // Tenant: localStorage.getItem('tenantId') == null ? '' : localStorage.getItem('tenantId')
    });
    return this.httpClient
      .post(BASE_URL + path, body, this.options)
      .pipe(catchError(this.formatErrors));
  }

  public delete(path: string): Observable<any> {
    this.options.headers = new HttpHeaders({
      'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
      'Content-Type': 'application/json',
      'Accept-Language': 'vi',
      // 'Tenant': localStorage.getItem('tenantId') == null ? '' : localStorage.getItem('tenantId')
    });
    return this.httpClient.delete(BASE_URL + path, this.options)
      .pipe(catchError(this.formatErrors));
  }

  public formatErrors(error: any): Observable<any> {
    return throwError(error.error);
  }

  /**
   * Get base url of web api.
   */
  public getBaseUrl() {
    return BASE_URL;
  }
}
