import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ApiService } from 'src/app/service/api.service';
import { map } from 'rxjs/operators';

const route_gets = 'api/v1/notifications?'
const update_route = 'api/v1/notifications/user-notifications'

@Injectable({ providedIn: 'root' })
export class NotificationSystemService {
  constructor(private apiService: ApiService,) {
  }

  getNotificationSystem(fromDate, toDate) {
    return this.apiService.get(route_gets + 'fromDate=' + fromDate + '&toDate=' + toDate).pipe(map(res => {
      return res;
    }));
  }

  updateNotificationSystem(request) {
    return this.apiService.post(update_route, request).pipe(map(res => {
      return res;
    }));
  }

  deteleNotificationSystem(id) {
    return this.apiService.delete(update_route + "/" + id).pipe(map(res => {
      return res;
    }));
  }
}