import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`
})
export class AppComponent {
  ngOnInit() {
    let hostName = window.location.hostname;
    if (hostName == 'benhnhan.phongkhamngoclan.vn') {
      localStorage.setItem('isHostNL', 'true');
    }
    else {
      localStorage.setItem('isHostNL', 'false');
    }
  }
}
