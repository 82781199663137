import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  templateUrl: './select-doctor.component.html',
  styleUrls: ['./select-doctor.component.css']
})
export class SelectDoctorComponent implements OnInit {
  doctorId: any;
  doctorModel: any;
  doctors = Array<any>();
  isShowErrors = null;

  constructor(
    public dialogRef: MatDialogRef<SelectDoctorComponent>,
    public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.doctors = data.doctors;
  }

  ngOnInit() {
    setTimeout(() => { });
  }

  selectionChangeDoctor(value) {
    this.doctorModel = this.doctors.filter(c => c.doctorId === value).map(c => c);
  }

  onSelectDoctor() {
    if (this.doctorId != null)
      this.dialogRef.close(this.doctorModel)
    else
      this.isShowErrors = true;
  }
}
