import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-edit-status-schedule',
  templateUrl: './edit-status-schedule.component.html',
  styleUrls: ['./edit-status-schedule.component.css']
})
export class EditStatusScheduleComponent implements OnInit {
  title: string;
  message: string;
  note: string;
  idStatus: string;
  
  constructor(public dialogRef: MatDialogRef<EditStatusScheduleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.title;
    this.message = data.message;
    this.idStatus = data.idStatus;
    this.note = data.note;
  }

  ngOnInit() {
  }

  onConfirm(): void {
    let send = { isTrue: true, note: this.note }
    this.dialogRef.close(send);
  }

  onDismiss(): void {
    let send = { isTrue: false, note: this.note }
    this.dialogRef.close(send);
  }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ConfirmDialogModel {

  constructor(public title: string, public message: string) {
  }
}
