export enum ViewPlatFormType {
    Mobile = 1,
    Web = 2,
    Application = 3
}

export enum ViewTypes {
    AppointmentSchedule = 'f471b635-3e75-40cd-a77c-9bf40f365d86',
    News = '615574e1-0f06-463a-99b5-0d8139c3d77e',
    ASQ3 = '77f2684b-77dd-4a6b-a03a-1f970604759c',
    Procedure = 'd5d9e7d3-5a2f-422d-9d78-aded3d236bc4',
    BabyKick = 'c4ba7dd5-261b-446a-9c27-77d88744c687',
    MeasureGlucose = '87379983-ebd9-441d-bc43-1a19aebab56f',
    History = '087e2e86-2089-42c9-99a3-b9888443dd1a',
    Newborn = 'aa4878a9-77aa-4eb8-a739-3ab4ee51dda9',
}
